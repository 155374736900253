import { ColorfulContainer } from '@/src/common/components/elements/Layout/Banners/StyledComponents'
import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import { DEFAULT_PARTNER_BANNER_TEXT } from '@/src/modules/partners/constants'
import Link from 'next/link'
import {
    PARTNER_BRAND_PAGE,
    PARTNER_CHECKOUT_PAGE,
    PARTNER_CONFIRMATION_PAGE,
} from '@/src/modules/pg-plus/constants'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { useRouter } from 'next/router'

type TPartnerBannerProps = {
    partnerData?: TPartnerPayload
}

const Content = styled('div', {
    width: '93vw',
    display: 'flex',
    ai: 'center',
    jc: 'center',
    color: '$white',
    padding: 8,
    fontSize: '1.1rem',
    flexWrap: 'wrap',
    margin: '0 auto',
    gap: 4,
    '@sm': {
        gap: 8,
    },
})

const StyledImage = styled(Image, {
    width: 'auto',
    height: 22,
    display: 'block',
    marginY: 'auto',
})

const PartnerBanner = ({ partnerData }: TPartnerBannerProps) => {
    const router = useRouter()

    const partnerName = partnerData?.partnerName ?? ''
    const partnerLogo =
        partnerData?.publisherLogoUrl1 ?? partnerData?.publisherLogoUrl2
    const storePageBrandId = Boolean(
        partnerData?.programConfig?.store_page_brand_id
    )

    if (storePageBrandId) {
        // Checkout uses brand logo
        if (
            router.pathname === PARTNER_CHECKOUT_PAGE ||
            router.pathname === PARTNER_CONFIRMATION_PAGE
        ) {
            return (
                <ColorfulContainer style={{ cursor: 'default', width: '100%' }}>
                    <Content>
                        In Partnership with
                        {partnerLogo && (
                            <StyledImage
                                src={partnerLogo}
                                alt={`${partnerName} logo`}
                                width={150}
                                height={50}
                                priority={true}
                                style={{ height: 32 }}
                            />
                        )}
                    </Content>
                </ColorfulContainer>
            )
        }

        // Partner brand page uses PGC logo
        return (
            <ColorfulContainer style={{ cursor: 'default', width: '100%' }}>
                <Content style={{ padding: '0 4px' }}>
                    <StyledImage
                        src="https://res.cloudinary.com/gift-card-granny/image/upload/v1726838799/PGSite/partner_images/Powered_By_qlmv6c.svg"
                        alt={`Powered By`}
                        width={65}
                        height={15}
                        style={{ marginTop: 2, marginRight: 10 }}
                        priority={true}
                    />
                    {partnerLogo && (
                        <StyledImage
                            src={
                                'https://res.cloudinary.com/gift-card-granny/image/upload/v1726772426/PGSite/partner_images/i9rcr5jikexdd0zgxswf.webp'
                            }
                            alt={`${partnerName} logo`}
                            width={150}
                            height={50}
                            priority={true}
                            style={{ height: 30 }}
                        />
                    )}
                </Content>
            </ColorfulContainer>
        )
    }

    return (
        <Link type={'button'} href={PARTNER_BRAND_PAGE}>
            <ColorfulContainer style={{ cursor: 'default', width: '100%' }}>
                <Content>
                    {partnerLogo && (
                        <>
                            <StyledImage
                                src={partnerLogo}
                                alt={`${partnerName} logo`}
                                style={{ height: 22 }}
                                width={150}
                                height={50}
                                priority={true}
                            />
                            {DEFAULT_PARTNER_BANNER_TEXT}
                        </>
                    )}
                </Content>
            </ColorfulContainer>
        </Link>
    )
}

export default PartnerBanner
