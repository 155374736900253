import { IMobileNavBaseItem } from '@/src/common/components/elements/Layout/Navigation/types'

export const corporateMobileNavMenuData: IMobileNavBaseItem[] = [
    {
        label: 'Visa',
        url: '/corporate/visa',
        testId: 'mobile-visa',
    },
    {
        label: 'Mastercard',
        url: '/corporate/mastercard',
        testId: 'mobile-mastercard',
    },
    {
        label: 'Brands',
        url: '/corporate/brands',
        testId: 'mobile-brands',
    },
    {
        label: 'Pricing',
        url: '/corporate/pricing',
        testId: 'mobile-pricing',
    },
    {
        label: 'Custom Solutions',
        testId: 'mobile-solutions',
        subSection: [
            {
                children: [
                    {
                        label: 'Co-branded Gift Cards',
                        url: '/corporate/cobrand',
                        testId: 'mobile-cobrand',
                    },
                    {
                        label: 'Custom Carriers',
                        url: '/corporate/carriers',
                        testId: 'mobile-carriers',
                    },
                    {
                        label: 'Custom Greeting Cards',
                        url: '/corporate/greetingcards',
                        testId: 'mobile-greetingcards',
                    },
                    {
                        label: 'White Label Services',
                        url: '/corporate/whitelabel',
                        testId: 'mobile-whitelabel',
                    },
                    {
                        label: 'Customizable APIs',
                        url: '/corporate/api',
                        testId: 'mobile-api',
                    },
                ],
            },
        ],
    },
    {
        label: 'Activate a Visa Card',
        url: '/activate',
        testId: 'mobile-activate',
    },
    {
        label: 'Check Balance',
        url: '/check-balance',
        testId: 'mobile-check-balance',
    },
    {
        label: 'Company',
        testId: 'mobile-company',
        subSection: [
            {
                children: [
                    {
                        label: 'Resources',
                        url: '/corporate/resources',
                        testId: 'mobile-resources',
                    },
                    {
                        label: 'Client Testimonials',
                        url: '/corporate/testimonials',
                        testId: 'mobile-testimonials',
                    },
                    {
                        label: 'Meet the Team',
                        url: '/corporate/meet-the-team',
                        testId: 'mobile-about-us',
                    },
                    {
                        label: 'Corporate FAQs',
                        url: '/corporate/faqs',
                        testId: 'mobile-faq',
                    },
                    {
                        label: 'Business Development',
                        url: '/corporate/business-development',
                        testId: 'mobile-business-development',
                    },
                ],
            },
        ],
    },
    {
        label: 'Log In',
        testId: 'mobile-login-section',
        subSection: [
            {
                label: 'For individuals',
                testId: 'mobile-individuals',
                children: [
                    {
                        label: 'Log in to BuyGiftCards',
                        url: '/login',
                        testId: 'mobile-login-consumer',
                        icon: 'fa-gift',
                    },
                ],
            },
            {
                label: 'For business',
                testId: 'mobile-business',
                children: [
                    {
                        label: 'Log in to Corporate Portal',
                        url: 'https://corp.perfectgift.com/login',
                        testId: 'mobile-external',
                        icon: 'fa-briefcase',
                    },
                    {
                        label: 'Request a corporate account',
                        url: '/corporate/get-started',
                        testId: 'mobile-request',
                        icon: 'fa-circle-plus',
                    },
                ],
            },
        ],
    },
]
